<template>
  <div>
    <custom-table
      ref="customTable"
      :columns="columns"
      :custom-view="customView"
      :footer-actions="footerActions"
      :sums="sums"
      api-url="/invoice-goods-no-export-list"
      table-height="calc(100vh - 196px)"
      download-file-name="hang-hoa.xlsx"
      @showDialogCreate="showDialogCreate"
      @removeNoInvoice="removeNoInvoice"
    ></custom-table>

    <v-dialog v-model="dialogCreate" persistent max-width="1024px">
      <GoodsNoInvoiceCreate
        v-if="dialogCreate"
        @close="hideDialogCreate"
        @refreshData="refreshCustomTable"
      />
    </v-dialog>
  </div>
</template>

<script>
import {
  IMAGE_OPTIONS,
  GOODS_STATUS_OPTIONS,
  CUSTOM_VIEW_BILL_GOODS_NO_INVOICE,
} from "@/libs/const";
import { httpClient } from "@/libs/http";

export default {
  name: "Index",
  components: {
    GoodsNoInvoiceCreate: () =>
      import("@/components/invoice/GoodsNoInvoiceCreate"),
  },
  data: () => ({
    customView: CUSTOM_VIEW_BILL_GOODS_NO_INVOICE,
    columns: [],
    footerActions: [],
    sums: [],
    isLoading: false,
    imageOptions: [...IMAGE_OPTIONS],
    statusOptions: [...GOODS_STATUS_OPTIONS],
    dialogCreate: false,
    updatingGoods: {},
  }),
  computed: {},
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  created() {
    const columns = [
      {
        type: "input-filter",
        label: this.$t("labels.barcode"),
        placeholder: this.$t("labels.barcode"),
        name: "customer_goods_barcode",
        hasSort: true,
        sortName: "customer_goods_barcode",
        key: "customer_goods_barcode",
        required: true,
      },
      {
        type: "select-filter",
        label: this.$t("labels.status"),
        placeholder: this.$t("labels.status"),
        name: "status",
        hasSort: true,
        sortName: "status",
        key: "status",
        required: true,
        options: this.statusOptions,
      },
      {
        type: "input-filter",
        label: this.$t("labels.sku"),
        placeholder: this.$t("labels.sku"),
        name: "sku",
        hasSort: true,
        sortName: "sku",
        key: "sku",
        required: true,
      },
      {
        type: "select-category",
        label: this.$t("labels.category"),
        placeholder: this.$t("labels.category"),
        name: "id_category",
        hasSort: true,
        sortName: "category_name",
        key: "category_name",
        required: true,
      },
      {
        type: "select-filter",
        label: this.$t("labels.image"),
        placeholder: this.$t("labels.image"),
        name: "image",
        hasSort: false,
        sortName: "url_images",
        key: "url_images",
        options: this.imageOptions,
        required: true,
        component: "Image",
      },
      {
        type: "input-filter",
        label: this.$t("labels.name"),
        placeholder: this.$t("labels.name"),
        name: "name",
        hasSort: true,
        sortName: "name",
        key: "name",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.goods_description"),
        placeholder: this.$t("labels.goods_description"),
        name: "description",
        hasSort: true,
        sortName: "description",
        key: "description",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.size"),
        placeholder: this.$t("labels.size"),
        name: "size",
        hasSort: true,
        sortName: "size",
        key: "size",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.unit"),
        placeholder: this.$t("labels.unit"),
        name: "unit",
        hasSort: true,
        sortName: "unit",
        key: "unit",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.price"),
        placeholder: this.$t("labels.price"),
        name: "price",
        hasSort: true,
        sortName: "price",
        key: "price",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.wholesale_price"),
        placeholder: this.$t("labels.wholesale_price"),
        name: "wholesale_price",
        hasSort: true,
        sortName: "wholesale_price",
        key: "wholesale_price",
        required: true,
      },
      {
        type: "button",
        label: this.$t("labels.note"),
        headerAction: "showDialogCreate",
        headerActionColor: "primary",
        headerActionLabel: this.$t("labels.add"),
        action: "removeNoInvoice",
        actionColor: "warning",
        actionLabel: this.$t("labels.delete"),
        required: true,
        buttonActions: [],
      },
    ];
    this.columns = [...columns];

    const footerActions = [];
    this.footerActions = [...footerActions];

    const sums = [];
    this.sums = [...sums];
  },
  mounted() {
    // this.getList()
  },
  methods: {
    refreshCustomTable() {
      this.$refs.customTable.getList();
    },
    showDialogCreate() {
      this.dialogCreate = true;
    },
    hideDialogCreate() {
      this.dialogCreate = false;
    },

    async removeNoInvoice(item) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/invoice-goods-no-export-save", {
          ids: [item.id],
          is_invoice: 1,
        });
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.update_success"));
        this.refreshCustomTable();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
